import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../containers/SaasModern/Documentation';
import proxy_1 from '../../../../../../common/src/assets/image/proxy_1.png';
import proxy_2 from '../../../../../../common/src/assets/image/proxy_2.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        If you connect to the internet through a proxy server, your proxy
        settings can be entered after checking the{' '}
        <strong>Use a proxy server</strong> box on the{' '}
        <strong>Proxy Server</strong> tab of the Options window.
      </p>
      <p>
        If you don't connect through a proxy, you should leave the{' '}
        <strong>Use a proxy server </strong>box unchecked to ensure PrintableCal
        can correctly connect to the internet.{' '}
      </p>
      <p>
        PrintableCal connects in order to import events and tasks from online
        sources. It also connects to check if any updates are available and to
        validate the license. Personal information is never transferred. You can
        find <Link to="/About/Privacy-Policy">our privacy policy here</Link>.
      </p>
      <h3>Specify a Proxy Server</h3>
      <p>
        If you have configured Microsoft Internet Explorer to access the
        internet through a proxy server, you can simply have PrintableCal reuse
        those settings. Otherwise, you can choose to manually specify a proxy
        server and port by clicking the <strong>Specify a proxy server</strong>{' '}
        option.
      </p>
      <p>
        <Image alt="" src={proxy_1} style={{ width: 891 }} />
      </p>
      <h3>Proxy Server Authentication</h3>
      <p>
        If your proxy server requires authentication to connect, check the{' '}
        <strong>Proxy server requires authentication </strong>box. This will
        allow you to enter the username, domain (optional), and password that
        are required to connect.
      </p>
      <p>
        <Image alt="" src={proxy_2} style={{ width: 891 }} />
      </p>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Options/Proxy-Server"
      commentsId="commentsplus_post_1204_1652"
      title="Proxy Server"
      description="PrintableCal can work behind a proxy server by configuring the Proxy Server settings."
      keywords="printable calendar, import calendars, proxy server"
      content={content}
    />
  );
};

export default Documentation;
